import React, { useEffect, useRef } from 'react';

import Printer from '../img/printer.svg';

import { Connect, Classes as ClassSection, OurTeam } from '../sections';
import { BottomNav, Button, Grid, Header, Row } from '../components';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const ClassesBtn = ({ref}: {ref: React.RefObject<HTMLDivElement>}) => {
    const handleClassesClick = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    }
    return <Button primary onClick={handleClassesClick}>See Classes</Button>;
}

const Classes = () => {
    let navigate = useNavigate();
    const classesRef = useRef<HTMLDivElement>(null);
    
    const handleShopAllClick = () => window.location.replace('http://store.ecovate3d.com');
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const header = `Technology Classes for All Ages`;
    const subHeader1 = `For both kids and adults, Ecovate 3D offers classes for all ages. From 3D printing 101 to CAD modeling to project workshops.`;
    const subHeader2 = `Anyone can be a maker here!`;

    const topRef = useRef<HTMLDivElement>(null);
    const teamRef = useRef<HTMLDivElement>(null);
    const connectRef = useRef<HTMLDivElement>(null);

    if (isMobile) {
        const menuItems = [
            {
                label: 'Scroll to Top',
                onClick: () => topRef.current?.scrollIntoView({behavior: 'smooth'}),
            },{
                label: 'Classes',
                onClick: () => classesRef.current?.scrollIntoView({behavior: 'smooth'}),
            },{
                label: 'Our Team',
                onClick: () => teamRef.current?.scrollIntoView({behavior: 'smooth'}),
            },{
                label: 'Connect With Us',
                onClick: () => connectRef.current?.scrollIntoView({behavior: 'smooth'}),
            }
        ];

        return <>
            <div className="section--mobile">
                <div ref={topRef} />
                <Header isMobile />
                <h1>{header}</h1>
                <h2>{subHeader1}</h2>
                <h2>{subHeader2}</h2>
                <img id="printer-img" src={Printer} />
                <ClassesBtn ref={classesRef} />
                <Button secondary onClick={handleShopAllClick}>Shop All</Button>
                <div ref={classesRef}>
                    <ClassSection />
                </div>
                <div ref={teamRef} />
                <OurTeam />
                <div ref={connectRef} />
                <Connect />
            </div>
            <BottomNav menuItems={menuItems} />
        </>
    }
  return (
    <>
        <div>
        <Grid>
            <Header />
            <Row>
                <div className="col-6">
                    <div>
                        <h1>{header}</h1>
                        <h2>{subHeader1}</h2>
                        <h2>{subHeader2}</h2>
                    </div>
                    <Row>
                        <div className="col-2"></div>
                        <div className="col-4">
                        <ClassesBtn ref={classesRef} />
                        </div>
                        <div className="col-4">
                        <Button secondary onClick={handleShopAllClick}>Shop All</Button>
                        </div>
                        <div className="col-2"></div>
                        <div></div>
                    </Row>
                </div>
                <div className="col-6">
                    <img id="printer-img" src={Printer} />
                </div>
            </Row>
        </Grid>
      </div>
      <div ref={classesRef}>
        <ClassSection />
      </div>
      <OurTeam />
      {/* <Connect /> */}
    </>
  );
}

export default Classes;
