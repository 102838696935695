import { Grid, Row } from '../components/Layout';
import Brian from '../img/brian-not-dongle.jpg';
import { useMediaQuery } from 'react-responsive';

const team = [
    {
        title: 'Head Instructor',
        name: 'Brian Yarborough',
        description: [
            `I'm Brian Yarborough, a graduate from NC State University, with a degree in Technology Education and Graphic Communications. I taught Technology Engineering and Design for Durham Public Schools for 9 years.`,
            `I seek out to specialize the education I provide to 3D printing and design.`,
            `At Ecovate 3D we hope to share and grow together in our projects for 3D modeling, 3D printing, and all the project builds we can make with it. I will teach basic introduction to programming needed to create working robotics projects with 3D printed bodies.`,
        ],
        image: Brian,
    }
]

const OurTeam = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    const text = <>
        <h2>Head Instructor - Brian Yarborough</h2>
        <p>
            I'm Brian Yarborough, a graduate from NC State University, with a degree in Technology Education and Graphic Communications. I taught Technology Engineering and Design for Durham Public Schools for 9 years.
        </p>
        <p>
            I seek out to specialize the education I provide to 3D printing and design.
        </p>
        <p>
            At Ecovate 3D we hope to share and grow together in our projects for 3D modeling, 3D printing, and all the project builds we can make with it. I will teach basic introduction to programming needed to create working robotics projects with 3D printed bodies.
        </p>
    </>;

    if (isMobile) {
        return <div className="section-2--mobile">
            <h1>Our Team</h1>
            {
                team.map((t) => <>
                    <h2>{t.title} - {t.name}</h2>
                    {
                        t.description.map((d) => <p>{d}</p>)
                    }
                    <img src={t.image} className="m-img-lg m-img-rounded" />
                </>)
            }
        </div>
    }

    return <div>
      <Grid>
        <Row>
          <h1>Our Team</h1>
        </Row>
        {
            team.map((t) => <Row>
                <div className="col-6">
                    <h2>{t.title} - {t.name}</h2>
                    {
                        t.description.map((d) => <p>{d}</p>)
                    }
                </div>
                <div className="col-6">
                    <div className="team-img">
                        <img src={t.image} />
                    </div>
                </div>
            </Row>)
        }
      </Grid>
    </div>
};

export default OurTeam;