import { useRef } from 'react';
import { Button as RmdButton } from '@react-md/button';

import { FontIcon } from "@react-md/icon";
import { useNavigate } from 'react-router-dom';
import {
    DropdownMenu,
    MenuItem,
} from "@react-md/menu";
import ActionSheet from 'actionsheet-react';

import type { ActionSheetRef } from 'actionsheet-react';
import { AutoComplete } from 'react-md';

const IconButton = ({children, onClick}: any) => (
    <RmdButton
        buttonType="icon"
        theme="primary"
        themeType="contained"
        onClick={onClick}
    >
        <FontIcon>{children}</FontIcon>
    </RmdButton>
);

type MenuItem = {
    label: string,
    onClick: Function,
}

const BottomNav = ({menuItems}: {menuItems: Array<MenuItem>}) => {
    const ref = useRef<ActionSheetRef>();
    let navigate = useNavigate();
    const handleClick = () => ref.current?.open();
    const handleHomeClick = () => navigate('/');
    const handleClassesClick = () => navigate('/classes');

    const handleOptionClick = (onClick: Function) => () => {
        ref.current?.close();
        onClick();
    }

    return <>
        <div id="m-bottom-nav-spacer" />
        <div id="m-bottom-nav">
            <IconButton onClick={handleHomeClick}>home</IconButton>
            <IconButton onClick={handleClick}>menu</IconButton>
            <IconButton onClick={handleClassesClick}>event_note</IconButton>
        </div>
        <ActionSheet ref={ref} sheetTransition="transform 0.2s ease-in-out">
            {menuItems.map((m) => <SheetOption onClick={handleOptionClick(m.onClick)}>{m.label}</SheetOption>)}
        </ActionSheet>
    </>
};

const SheetOption = ({children, onClick}: any) => <>
    <div style={style.border} />
    <div style={style.item} onClick={onClick}>{children}</div>
    <div style={style.border} />
</>;

const style = {
    content: {
      height: 300,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    item: {
        height: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    border: {
        height: 0,
        border: '1px solid #DDD',
        width: '80%',
        margin: 'auto',
    }
  };

export default BottomNav;