import { Grid, Row } from '../components/Layout';
import { useMediaQuery } from 'react-responsive';

const Connect = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    if (isMobile) {
        return <div className="section-2--mobile">
            <h1>Connect With Us</h1>
            <h2>VISIT OUR STORE</h2>
            <p>14 Glenwood Ave #21<br />Raleigh, NC 27603</p>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d572.1552412913121!2d-78.64751945686929!3d35.7852301622715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ac5f666ae66a31%3A0x84276bcd05cb17b!2s404%20Glenwood%20Ave%2C%20Raleigh%2C%20NC%2027603!5e0!3m2!1sen!2sus!4v1660100812925!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{border:0}}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>;
    }

    return <div>
        <Grid>
            <Row>
                <h1>Connect With Us</h1>
            </Row>
            <Row>
                <div className="col-6">
                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1618.3763694836766!2d-78.64816963995442!3d35.781449690303866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ac5f2a0b686459%3A0x8aa6e1044cd1e5f!2sEcovate%203D!5e0!3m2!1sen!2sus!4v1679847504088!5m2!1sen!2sus"
                            width="600"
                            height="450"
                            style={{border:0}}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
                <div className="col-6">
                    <Row>
                        <div className="col-6">
                            <h2>VISIT OUR STORE</h2>
                            <p>14 Glenwood Ave #21<br />Raleigh, NC 27603</p>
                        </div>
                        <div className="col-6">
                            <h2>SOCIAL MEDIA</h2>
                            <p>
                                <a href="https://www.facebook.com/ecovate3d">Facebook</a>
                                <br />
                                <a href="https://www.instagram.com/ecovate3d">Instagram</a>
                            </p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6">
                            <h2>Create@Ecovate3D.com</h2>
                        </div>
                    </Row>
                </div>
            </Row>
        </Grid>
    </div>
};

export default Connect;