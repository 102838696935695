import { Grid, Header, Row } from '../components';

import ComingSoon from '../img/coming-soon.jpg';

import Connect from '../sections/Connect';
import { useMediaQuery } from 'react-responsive';

const Classes = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const header = `Coming Soon!`;
    const subHeader1 = `We are working on adding sign-ups for our classes and workshops.`;
    const subHeader2 = `Check back this weekend, or email Create@Ecovate3D.com and ask to be notified when class sign-ups are live!`;

    if (isMobile) {
        return <div className="section--mobile">
            <Header isMobile />
            <h1>{header}</h1>
            <h2>{subHeader1}</h2>
            <h2>{subHeader2}</h2>
            <img id="printer-img" src={ComingSoon} />
            <Connect />
        </div>
    }
  return (
    <>
        <div>
        <Grid>
            <Header />
            <Row>
                <div className="col-6">
                    <Row>
                        <div className="col-12">
                            <h1>{header}</h1>
                            <h2>{subHeader1}</h2>
                            <h2>{subHeader2}</h2>
                        </div>
                    </Row>
                </div>
                <div className="col-6">
                    <img id="printer-img" src={ComingSoon} />
                </div>
            </Row>
        </Grid>
      </div>
      <Connect />
    </>
  );
}

export default Classes;
