import Button from '../components/Button';
import Listing from '../components/Listing';

import { useMediaQuery } from 'react-responsive';
import classes from './classes.data';

const suggestionsText = <p>
  Being a new & small local business, we take suggestions for class times!
  If you would like an alternate class time or a private class for individuals or group - send us an email at create@ecovate3d.com and we'll get you set up!
  Is there something you really want to learn in the 3D printing space? Email us!
</p>;

const Classes = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const handleSignUpClick = () => window.location.replace('https://www.tickettailor.com/events/ecovate3d/');

  if (isMobile) {
    return <div className="section-2--mobile">
      <h1>Classes</h1>
      <div>
        {classes.map((c) => <Listing {...c} />)}
      </div>
      <Button primary onClick={handleSignUpClick}>Sign Up!</Button>
      {suggestionsText}
    </div>
  }

  return (
    <div className="flex-grid">
      <div className="flex-full-width">
        <h1>Classes</h1>
      </div>
      <div className="flex-full-width">
        <h2>Click on a class below to see more information, or to sign up for the class!</h2>
      </div>
      {
        classes.map((c, i) => <Listing key={`flex-grid-listing-${i}`} {...c} />)
      }
      {
        [...Array(3)].map((e, i) => <div key={`flex-grid-spacer-${i}`} className="listing--hidden"></div>)
      }
      <div className="flex-line-break"></div>
      <div></div>
      <Button primary onClick={handleSignUpClick} style={{width: '120px'}}>Sign Up!</Button>
      <div></div>
      <div className="flex-full-width">
        {suggestionsText}
      </div>
    </div>
  );
};

export default Classes;