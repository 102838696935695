import { Button as RmdButton } from "@react-md/button";
import { MouseEventHandler } from "react";
import styles from "./customButton.module.scss";

type Props = {
    id?: string,
    children: any,
    className?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    primary?: boolean,
    secondary?: boolean,
    style?: any,
}

const Button = ({id, children, className, onClick, primary, secondary, style}: Props) => {
    return <RmdButton
        id={id || "custom-themed-button-1"}
        themeType="contained"
        theme="secondary"
        className={`${styles.container} ${className} custom-btn ${primary && 'btn-primary'} ${secondary && 'btn-secondary'}`}
        onClick={onClick}
        style={style}
    >
        {children}
    </RmdButton>;
}

export default Button;
