import { useRef } from 'react';

import { BottomNav, Button, Grid, Header, Row } from '../components';

import Printer from '../img/printer.svg';
import Connect from '../sections/Connect';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    let navigate = useNavigate();
    const handleClassesClick = () => navigate('/classes');
    const handleShopAllClick = () => window.location.replace('http://store.ecovate3d.com');
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    const header = `Raleigh's One Stop 3D Printing Shop`;
    const subHeader1 = `A one-stop 3D Printing shop in Glenwood South. Printers, filament, supplies, maintenance, repairs, and education.`;
    const subHeader2 = `Come visit our store at 14 Glenwood Ave #21, Raleigh.`;

    const topRef = useRef<HTMLDivElement>(null);
    const connectRef = useRef<HTMLDivElement>(null);

    if (isMobile) {
        const menuItems = [
            {
                label: 'Scroll to Top',
                onClick: () => topRef.current?.scrollIntoView({behavior: 'smooth'}),
            },{
                label: 'Connect With Us',
                onClick: () => connectRef.current?.scrollIntoView({behavior: 'smooth'}),
            }
        ];

        return <>
            <div className="section--mobile">
                <div ref={topRef} />
                <Header isMobile />
                <h1>{header}</h1>
                <h2>{subHeader1}</h2>
                <h2>{subHeader2}</h2>
                <img id="printer-img" src={Printer} />
                <Button primary onClick={handleClassesClick}>Classes</Button>
                <Button secondary onClick={handleShopAllClick}>Shop All</Button>
                <div ref={connectRef} />
                <Connect />
            </div>
            <BottomNav menuItems={menuItems} />
        </>
    }

  return (
    <>
      <div>
        <Grid>
            <Header />
            <Row>
                <div className="col-6">
                    <Row>
                        <div className="col-12">
                            <h1>{header}</h1>
                            <h2>{subHeader1}</h2>
                            <h2>{subHeader2}</h2>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-2"></div>
                        <div className="col-4">
                        <Button primary onClick={handleClassesClick}>Classes</Button>
                        </div>
                        <div className="col-4">
                        <Button secondary onClick={handleShopAllClick}>Shop All</Button>
                        </div>
                        <div className="col-2"></div>
                        <div></div>
                    </Row>
                </div>
                <div className="col-6">
                    <img id="printer-img" src={Printer} />
                </div>
            </Row>
        </Grid>
      </div>
      <Connect />
    </>
  );
}

export default Home;
