import Class1 from '../img/classes/101-all-ages.jpg';
import Class2 from '../img/classes/intro-to-design.jpg';
import Class3 from '../img/classes/earring-class.jpg';
import Class4 from '../img/classes/101-kids.jpg';
import Class5 from '../img/classes/lego-house.jpg';
import Class6 from '../img/classes/improve-printing.jpg';
import Class7 from '../img/classes/props.jpg';

const classes = [
    {
        img: Class1,
        title: `3D PRINTING 101`,
        body1: `Did you recently get a printer? Are you just dipping your toes into printing?`,
        body2: `Learn all the basics of 3D printing in our all ages class!`,
        link: `https://buytickets.at/ecovate3d/745610/r/ecovate-website`,
    },{
        img: Class2,
        title: 'INTRO TO DESIGN',
        body1: `Ready to turn your ideas into reality!`,
        body2: `In this all ages class, we will teach an intro to design with Tinkercad and how to make your project print ready!`,
        link: `https://buytickets.at/ecovate3d/745726/r/ecovate-website`,
    },{
        img: Class3,
        title: `3D Projects: Design & Print your own earrings`,
        body1: `In this 90 minute class, we will teach you how to 3D model your own custom earring design, and print it from our selection of beautiful filaments.`,
        body2: ``,
        link: `https://buytickets.at/ecovate3d/745729/r/ecovate-website`,
    },{
        img: Class4,
        title: `3D PRINTING 101 - FOR KIDS`,
        body1: `Are you wanting to learn something new about tech?`,
        body2: `This kid-friendly class is geared for ages 10-15, though open to other ages.`,
        link: `https://buytickets.at/ecovate3d/745731/r/ecovate-website`,
    },{
        img: Class5,
        title: '3D PROJECTS - LEGO HOUSE',
        body1: `Learn how to reverse engineer Lego bricks to create your own unique bricks.`,
        body2: `Design your own house with your own new brick designs.`,
        link: `https://buytickets.at/ecovate3d/745750/r/ecovate-website`,
    },{
        img: Class6,
        title: 'IMPROVE YOUR 3D PRINTING',
        body1: `We will take an in-depth look at slicer settings, how to hone temperatures for different filaments, and workshop best practices in leveling and maintenance.`,
        body2: ``,
        link: `https://buytickets.at/ecovate3d/751977/r/ecovate-website`,
    },{
        img: Class7,
        title: 'CUSTOM COSPLAY PROP DESIGN!',
        body1: `Design your fantasy props to display in your house or add to your next cosplay. We will cover general design, as well as how to divide large models for easy printing and connecting.`,
        body2: ``,
        link: `https://buytickets.at/ecovate3d/751973/r/ecovate-website`,
    }
];

export default classes;
