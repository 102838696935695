import { useMediaQuery } from 'react-responsive';

const Listing = ({children, img, title, body1, body2, link}: any) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  
  const handleListingClick = () => window.location.replace(link);

  return <div className={`listing ${!isMobile && 'clickable'}`} onClick={handleListingClick}>
    <div className="listing-img">
      <img src={img} />
    </div>
    <div>
      <p className="listing-title">{title}</p>
    </div>
    <div>
      <p className="listing-body">{body1}</p>
      <p className="listing-body">{body2}</p>
    </div>
    {children}
  </div>
}

export default Listing;