import React, { useRef } from 'react';
import './App.css';
import { Route, Routes, BrowserRouter, HashRouter, Navigate, Link } from 'react-router-dom';
import BottomNav from './components/BottomNav';

import Home from './pages/Home';
import Classes from './pages/Classes';
import ComingSoon from './pages/ComingSoon';

const App = () => <>
<HashRouter>
  <div id="bg-img">
  </div>
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/classes" element={<Classes />} />
    <Route path="/comingSoon" element={<ComingSoon />} />
  </Routes>
  {/* <div id="m-bottom-nav-spacer" /> */}
  {/* <BottomNav /> */}
  </HashRouter>
</>;


export default App;
