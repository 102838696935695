import Logo from '../img/logo.png';
import { Grid, Row } from './Layout';
import { useNavigate } from 'react-router-dom';

const Header = ({isMobile}: {isMobile?: boolean}) => {
    let navigate = useNavigate();
    const handleLogoClick = () => navigate('/');

    return isMobile
        ? <div>
            <img id="logo" src={Logo} onClick={handleLogoClick} />
        </div>
        : <Row>
            <img id="logo" src={Logo} onClick={handleLogoClick} />
        </Row>;
}

export default Header;
